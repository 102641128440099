// Guest
export const PATH_REGISTER_USER = 'register-user';
export const PATH_REGISTER_MODEL = 'register-model';
export const PATH_RESTORE_PASSWORD = 'restore-password';
export const PATH_RESET_PASSWORD = 'reset-password';
export const PATH_AGENCY_LOGIN = 'panel';

// Public
export const PATH_PROFILE = 'profile';
export const PATH_BLOG = 'blog';
export const PATH_VIDEOS = 'videos';
export const PATH_PHOTOS = 'photos';
export const PATH_EROSTORIES = 'erotic-stories';
export const PATH_AWARDS = 'awards';
export const PATH_UNDERWEAR = 'underwear';
export const PATH_INTERVIEWS = 'interviews';
export const PATH_INTERVIEW = 'interview';
export const PATH_SITE_NEWS = 'site-news';
export const PATH_ARTICLE = 'article';
export const PATH_LINKS = 'links';
export const PATH_LATEST_ITEMS = 'latest-items';
export const PATH_SEARCH = 'search';
export const PATH_SUGAR_BABY = 'sugar-baby';
export const PATH_AURA_CONTEST = 'aura-contest';

export const PATH_GIRLS = 'girls/';
export const PATH_BOYS = 'boys/';
export const PATH_RAGAZZE = 'ragazze/';
export const PATH_RAGAZZI = 'ragazzi/';
export const indexPathAliases = [
  PATH_GIRLS,
  PATH_BOYS,
  PATH_RAGAZZE,
  PATH_RAGAZZI,
];

export const PATH_HELP = 'help';
export const PATH_HELP_PAYMENT = 'help/payment';

export const PATH_RULES = 'rules';
export const PATH_RULES_HOW_IT_WORKS = 'rules/how-it-works';
export const PATH_RULES_VIDEO_CHAT = 'rules/chat';
export const PATH_RULES_VIDEO_CHAT_AGREEMENT = 'rules/chat-agreement';
export const PATH_RULES_182257 = 'rules/182257';
export const PATH_RULES_TERMS = 'rules/terms';
export const PATH_RULES_PRIVACY = 'rules/privacy';
export const PATH_RULES_REFUND = 'rules/refund';
export const PATH_RULES_REWARDS = 'rules/rewards';

export const PATH_RIVNETWORK = 'rivnetwork';

export const PATH_LARIX = 'larix';
export const PATH_RIVSTREAMER = 'rivstreamer';
export const PATH_WEBCAM_MODEL_WORKSPACE_HINTS = 'webcam-model-workspace-hints';

export const PATH_LEGAL_EU_DSA = 'legal/eu-dsa';
export const PATH_LEGAL_CONTENT_REMOVAL = 'legal/content-removal';
export const PATH_LEGAL_DMCA_COMPLAINT = 'legal/dmca-copyright-infringement';

// halloweek
// black-week
// christmas
// san-valentino
export const PATH_EVENT = 'san-valentino';
export const PATH_EVENT_AWARDS = 'site-event-awards';

export const PATH_LOADING = 'loading';
export const PATH_NOT_FOUND = 'not-found';
export const PATH_SYSTEM_MESSAGE = 'system-message';
export const PATH_SYSTEM_ERROR = 'system-error';
export const PATH_SITEMAP = 'sitemap.xml';
export const PATH_ROBOTS = 'robots.txt';

// User
export const PATH_ACCOUNT_SETTINGS = 'account-settings';
export const PATH_NOTIFICATION_SETTINGS = 'notification-settings';
export const PATH_PAYOUT_SETTINGS = 'payout-settings';
export const PATH_PARTNERS = 'partners';
export const PATH_CHANGE_PASSWORD = 'change-password';
export const PATH_DELETE_ACCOUNT = 'delete-account';
export const PATH_PUBLIC_PHOTOS = 'public-photos';
export const PATH_WEBCAM_SETTINGS = 'webcam-settings';
export const PATH_SPEED_TEST = 'speed-test';
export const PATH_FEEDBACK = 'feedback';
export const PATH_FAVORITES = 'favorites';
export const PATH_AFFILIATES = 'affiliates';
export const PATH_BANNED_USERS = 'banned-users';
export const PATH_BLACKLIST = 'blacklist';
export const PATH_VISITORS = 'visitors';
export const PATH_CREDITS = 'credits';
export const PATH_REVENUE = 'revenue';
export const PATH_PURCHASES = 'purchases';
export const PATH_ITEMS_FOR_SALE = 'items-for-sale';
export const PATH_DIALOGS = 'dialogs';
export const PATH_DIALOG = 'dialog';
export const PATH_INTERVIEW_FORMS = 'interview-forms';
export const PATH_OWN_INTERVIEWS = 'own-interviews';
export const PATH_REQUESTS = 'requests';
export const PATH_INTERVIEWER = 'interviewer';
export const PATH_MAKE_FIRST_DEPOSIT = 'make-first-deposit';
export const PATH_MAKE_NEW_DEPOSIT = 'make-new-deposit';
export const PATH_APPOINTMENTS = 'appointments';
export const PATH_PAYOUT_REQUEST_NOTICE = 'payout-request-notice';
export const PATH_NEWS_FEED = 'news-feed';
export const PATH_BLOG_SETTINGS = 'blog-settings';
export const PATH_EMOTICON_SHOP = 'emoji-shop';
export const PATH_PRIZES = 'prizes';
export const PATH_FLOWER_DELIVERY = 'flower-delivery';

export const PATH_BALANCE_ADJUSTMENTS = 'balance/adjustments';
export const PATH_BALANCE_LOSSES_COMPENSATIONS = 'balance/losses-compensations';
export const PATH_BALANCE_EXPIRED_CREDITS = 'balance/expired-credits';
export const PATH_BALANCE_PURCHASES = 'balance/purchases';
export const PATH_BALANCE_FEES = 'balance/fees';
export const PATH_BALANCE_SALES = 'balance/sales';
export const PATH_BALANCE_PURCHASED_REAL_GIFTS = 'balance/purchased-real-gifts';
export const PATH_BALANCE_PURCHASED_SERVICES = 'balance/purchased-services';
export const PATH_BALANCE_REVENUE_CONVERSIONS = 'balance/revenue-conversions';

// User (incomplete registration)
export const PATH_SIGNUP_INFO = 'signup-info';
export const PATH_SIGNUP = 'signup';

// Banned user
export const PATH_UNBAN = 'unban';

export const PATH_AURA_PREFIX = 'dashboard/';
export const PATH_AURA_ACCOUNT = `${PATH_AURA_PREFIX}account`;
export const PATH_AURA_BLUECHECK = `${PATH_AURA_PREFIX}bluecheck`;
export const PATH_AURA_DIALOGS = `${PATH_AURA_PREFIX}${PATH_DIALOGS}`;
export const PATH_AURA_DIALOG = `${PATH_AURA_PREFIX}${PATH_DIALOG}`;
export const PATH_AURA_LARIX = `${PATH_AURA_PREFIX}${PATH_LARIX}`;
export const PATH_AURA_PAYING_MEMBERS = `${PATH_AURA_PREFIX}paying-members`;
export const PATH_AURA_PRESENTATION = `${PATH_AURA_PREFIX}presentation`;
export const PATH_AURA_PRIVACY = `${PATH_AURA_PREFIX}privacy`;
export const PATH_AURA_PROFILE = `${PATH_AURA_PREFIX}personal-data`;
export const PATH_AURA_PROFILE_PHOTO = `${PATH_AURA_PREFIX}profile-photo`;
export const PATH_AURA_PUBLIC_PHOTOS = `${PATH_AURA_PREFIX}${PATH_PUBLIC_PHOTOS}`;
export const PATH_AURA_RIVSTREAMER = `${PATH_AURA_PREFIX}${PATH_RIVSTREAMER}`;
export const PATH_AURA_SPEED_TEST = `${PATH_AURA_PREFIX}${PATH_SPEED_TEST}`;
export const PATH_AURA_STATISTICS = `${PATH_AURA_PREFIX}statistics`;
export const PATH_AURA_SUPPORT = `${PATH_AURA_PREFIX}support`;
export const PATH_AURA_WEBCAM_SETTINGS = `${PATH_AURA_PREFIX}${PATH_WEBCAM_SETTINGS}`;
